//import { required, email, minLength } from "@vuelidate/validators";

import User from "./users"


export default class Log {
  constructor() {
    this.id = null;
    this.description = null;
    this.endPoint = null;
    this.operatorName = null;
    this.operation = null;
    this.address = true;
    this.dtOperation = null;
    this.user = new User();
    
  }

}
